import { createMuiTheme } from '@material-ui/core/styles';

const headerFontFamily = '"Nunito Sans", Arial, Helvetica, sans-serif';

const theme = createMuiTheme({
  direction: 'ltr',
  typography: {
    fontFamily: ['"Nunito Sans"', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    h1: { fontFamily: headerFontFamily },
    h2: { fontFamily: headerFontFamily },
    h3: { fontFamily: headerFontFamily },
    h4: { fontFamily: headerFontFamily },
    h5: { fontFamily: headerFontFamily },
    h6: { fontFamily: headerFontFamily },
  },
  palette: {
    primary: {
      light: '#7db447',
      main: '#3BAE65',
      dark: '#254b00',
    },
    secondary: {
      main: '#FCBF09',
    },
    info: {
      main: '#F2F2F2',
    },
  },
});

export default theme;
