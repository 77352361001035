import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProfileSite = {
  name: string;
  lat: number;
  lng: number;
};

export type CoveragePeriod = {
  start: string;
  end: string;
};

export type Profile = {
  name: string;
  anchorValue: number;
  site: ProfileSite;
  period: CoveragePeriod;
};
export type ProfileState = Profile | {};

const profileSlice = createSlice({
  name: 'profile',
  initialState: {} as ProfileState,
  reducers: {
    setProfile: (state, action: PayloadAction<Profile>) => action.payload,
  },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
