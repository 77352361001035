import axios from 'axios';
import { API_ROOT } from '../env';
import { login, TokenData } from '../reducers/user';
import { Dispatch } from 'redux';

const LOGIN_ENDPOINT = `${API_ROOT}/auth`;

export default function swapMagicToken(
  magicToken: string,
  dispatch: Dispatch<any>,
  handleError: (err: string) => void,
) {
  axios
    .post(LOGIN_ENDPOINT, null, {
      headers: {
        Authorization: `Bearer ${magicToken}`,
      },
    })
    .then(({ data }: { data: TokenData }) => {
      localStorage.setItem('apitoken', JSON.stringify(data));
      dispatch(login(data.token));
    })
    .catch((e) => {
      // TODO redirect to error page
      console.error('saturn error', e);
      if (e.response?.status === 401 && e.response?.data.error_code === 'UNREGISTERED_USER') {
        handleError(e.response?.data.error_message);
      } else {
        handleError('Sorry! Something went wrong during login, please try again later');
      }
    });
}
