import { TextFieldProps } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

export default function AmountTextField(props: TextFieldProps) {
  const { onChange, value, label, ...other } = props;

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      {...other}
    />
  );
}
