import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticated } from '../reducers/user';

const PrivateRoute = ({ component: Component, path, location, ...rest }: any) => {
  const isAuth = useSelector(isAuthenticated);

  const render = (props: any) => {
    if (isAuth === true) {
      return <Component {...props} />;
    } else {
      return <Redirect to="/login" />;
    }
  };

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
